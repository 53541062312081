import axios from "axios";
import { getBackendBaseUrl, getSettings } from "../variables";
import { ApiResponse, Identity, IdpType, InvitationResponse, LogoutData, SignupTokenResponse, User } from "./types";

const anonymouseBaseUri = getBackendBaseUrl("anonymous/admin");
const emailVerificationBaseUri = getBackendBaseUrl("emailverification");

const requestLoginToken = async (code: string, idp: IdpType | undefined): Promise<ApiResponse<User>> => {
  const { data } = await axios.post<ApiResponse<User>>(
    `${getSettings().PORTAL_BACKEND_BASE_URI}/sso/auth/codeExchange`,
    {
      clientId: getSettings().MICROSOFT_CLIENT_ID,
      RedirectUri: getSettings().SIGNIN_REDIRECT_URI,
      AuthCode: code,
      IdpType: idp || "Microsoft",
    }
  );
  return data;
};

const requestSignupToken = async (code: string, invitationId: string, idp: IdpType): Promise<SignupTokenResponse> => {
  const { data } = await axios.post<SignupTokenResponse>(`${anonymouseBaseUri}/Invitations/${invitationId}/redeem`, {
    clientId: getSettings().MICROSOFT_CLIENT_ID,
    RedirectUri: getSettings().SIGNUP_REDIRECT_URI,
    AuthCode: code,
    IdpType: idp,
  });
  return data;
};

const logout = async (): Promise<ApiResponse<LogoutData>> => {
  const { data } = await axios.post<ApiResponse<LogoutData>>(
    `${getSettings().PORTAL_BACKEND_BASE_URI}/sso/auth/logout`,
    {
      userFlow: "",
    }
  );
  return data;
};

const getInvitation = async (invitationId: string): Promise<ApiResponse<InvitationResponse>> => {
  const { data } = await axios.get<ApiResponse<InvitationResponse>>(
    `${getSettings().PORTAL_BACKEND_BASE_URI}/anonymous/admin/Invitations/${invitationId}/isAcceptable`
  );
  return data;
};

const resendEmailVerification = async () => {
  await axios.post<ApiResponse<Identity>>(`${emailVerificationBaseUri}/EmailVerifications/submit`);
};

const verifyEmail = async (verificationId: string) => {
  const { data } = await axios.post<ApiResponse<unknown>>(
    `${emailVerificationBaseUri}/EmailVerifications/${verificationId}/complete`
  );
  return data;
};

export const api = {
  requestLoginToken,
  requestSignupToken,
  logout,
  getInvitation,
  resendEmailVerification,
  verifyEmail,
};
