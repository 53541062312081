import { Breakpoints, Breakpoint as MuiBreakpoint, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PropsWithChildren } from "react";

interface Props {
  from?: MuiBreakpoint | number;
  to?: MuiBreakpoint | number;
}

const Breakpoint = ({ from, to, children }: PropsWithChildren<Props>) => {
  const getMediaQuery = ({ from, to }: Props, breakpoints: Breakpoints) => {
    if (from === undefined) {
      return to === undefined ? "" : breakpoints.down(to);
    }
    if (to === undefined) {
      return breakpoints.up(from);
    }
    return breakpoints.between(from, to);
  };

  const theme = useTheme();
  const shouldRender = useMediaQuery(getMediaQuery({ from, to }, theme.breakpoints));
  return shouldRender ? <>{children}</> : null;
};

export default Breakpoint;
