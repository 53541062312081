import React from "react";
import { Navigate, useSearchParams } from "react-router";
import { api } from "../api/api";
import { ApiError } from "../api/types";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import useFetch from "../hooks/useFetch";
import { pageNames } from "../pages";
import { getRedirectUrl } from "../variables";
import { ReferenceVariables } from "./RedirectKeys";

export default function VerifyEmailPage() {
  const [params] = useSearchParams();
  const verificationId = params.get(ReferenceVariables.VerificationId);
  const [verified, error] = useVerificationValidator(verificationId);

  if (!verificationId) {
    return <Navigate to={`/${pageNames.invalidLink}`} />;
  }

  if (verified === true) {
    window.location.href = getRedirectUrl();
  }

  if (error?.code === 401) {
    return <Navigate to={buildSignInRedirectUrl(verificationId)} />;
  }
  if (verified === false || error !== undefined) {
    return <Navigate to={`/${pageNames.invalidLink}`} />;
  }

  return <FullScreenLoader title="" subtitle="" />;
}

function useVerificationValidator(verificationid: string | null): [boolean | undefined, ApiError | undefined] {
  const [success, setSuccess] = React.useState<boolean>();

  const verifyEmail = React.useCallback(() => {
    if (verificationid === null) {
      return Promise.reject(new Error("No verificationid"));
    }

    return api.verifyEmail(verificationid);
  }, [verificationid]);

  const [, error] = useFetch(verifyEmail, () => setSuccess(true));

  return [success, error];
}

function buildSignInRedirectUrl(verificationId: string) {
  const redirectParams = new URLSearchParams();
  redirectParams.append(ReferenceVariables.VerificationId, verificationId);
  const urlToRedirect = `/${pageNames.signIn}?${redirectParams.toString()}`;
  return urlToRedirect;
}
