import { IdpType } from "./api/types";
import { defined } from "./utils/typeHelper";

const importEnvVariable = (variableName: string): string | undefined =>
  import.meta.env[variableName] as string | undefined;

type EnvVar = ReturnType<typeof getVariables>;

let variables: EnvVar;

const getVariables = () => ({
  MICROSOFT_AUTH_ENDPOINT: defined(importEnvVariable("REACT_APP_MICROSOFT_AUTH_ENDPOINT")),
  MICROSOFT_CLIENT_ID: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_MICROSOFT_CLIENT_ID")),
  SIGNIN_REDIRECT_URI: importEnvVariable("REACT_APP_SIGNIN_REDIRECT_URI"),
  SIGNUP_REDIRECT_URI: importEnvVariable("REACT_APP_SIGNUP_REDIRECT_URI"),
  PORTAL_ACCESS_SCOPE_MICROSOFT: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE_MICROSOFT")),
  PORTAL_BACKEND_BASE_URI: defined(importEnvVariable("REACT_APP_PORTAL_BACKEND_BASE_URI")),
  PAGE_TITLE: defined(importEnvVariable("REACT_APP_PAGE_TITLE")),
  AUTH_COOKIE_NAME: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_AUTH_COOKIE_NAME")),
  APP_INSIGHTS_CONN_STRING: defined(importEnvVariable("REACT_APP_APP_INSIGHTS_CONN_STRING")),
  ADMIN_PORTAL_URI: defined(importEnvVariable("REACT_APP_ADMIN_PORTAL_URI")),
  ALLOWED_REDIRECT_ORIGINS: defined(importEnvVariable("REACT_APP_ALLOWED_REDIRECT_ORIGINS")),
});

const initVariables = () => {
  if (variables === undefined) {
    variables = getVariables();
  }
};

const getSettings = (): EnvVar => {
  initVariables();
  return variables;
};

const getBackendBaseUrl = (segment: string) => {
  const localServerUrl = importEnvVariable("REACT_APP_LOCAL_SERVER");
  if (localServerUrl) {
    return localServerUrl;
  }
  return `${getSettings().PORTAL_BACKEND_BASE_URI}/${segment}`;
};

const getRedirectUrlCore = (providedRedirectUrlState?: string) => {
  const allowedOriginsStr = getSettings().ALLOWED_REDIRECT_ORIGINS;
  if (providedRedirectUrlState !== undefined) {
    const stateParams = getStateParams(providedRedirectUrlState);
    const origin = stateParams["origin"];
    if (origin !== undefined) {
      const redirectUrl = new URL(origin);
      const origins = allowedOriginsStr.split(";");
      if (
        origins.some((urlStr) => {
          const url = new URL(urlStr);
          return url.origin === redirectUrl.origin;
        })
      ) {
        return `${redirectUrl.origin}`;
      }
    }
  }
  return getSettings().ADMIN_PORTAL_URI;
};

const getRedirectUrl = (providedRedirectUrlState?: string) => {
  const url = getRedirectUrlCore(providedRedirectUrlState);
  if (providedRedirectUrlState !== undefined) {
    const urlParams = new URLSearchParams();
    urlParams.set("state", providedRedirectUrlState);
    return `${url}/?${urlParams}`;
  }
  return url;
};

const getCodeReturnTypeRedirectUrl = (
  authCode: string,
  providedRedirectUrlState: string | undefined,
  idp: IdpType | undefined
) => {
  const url = getRedirectUrlCore(providedRedirectUrlState);
  if (providedRedirectUrlState !== undefined) {
    const urlParams = new URLSearchParams();
    urlParams.set("state", providedRedirectUrlState);
    urlParams.set("code", authCode);
    if (idp !== undefined) {
      urlParams.set("idp", idp);
    }
    return `${url}/?${urlParams}`;
  }
  return url;
};

export function getStateParams(oauthState: string): Record<string, string> {
  try {
    return JSON.parse(oauthState) as Record<string, string>;
  } catch {
    return {};
  }
}

export { getBackendBaseUrl, getCodeReturnTypeRedirectUrl, getRedirectUrl, getSettings };
