import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { ApiResponse } from "../api/types";

interface ExtendedAxiosError<T = unknown, D = unknown> extends AxiosError<T, D> {
  config?: ExtendedAxiosConfig<D>;
}

interface ExtendedAxiosConfig<D = unknown> extends InternalAxiosRequestConfig<D> {
  skipAuthRefresh?: boolean;
}

export const setUpAxios = () => {
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      const axiosError = error as ExtendedAxiosError;
      if (!axiosError.isAxiosError) {
        return Promise.reject(axiosError);
      }

      return Promise.reject(axiosError);
    }
  );
};

export const getStatusCodeFromResponseError = (error: unknown): number | undefined => {
  const axiosError = error as ExtendedAxiosError;
  if (!axiosError.isAxiosError) {
    return undefined;
  }

  return axiosError.response?.status;
};

export const getMessageFromResponseError = (error: unknown): string | undefined => {
  const axiosError = error as ExtendedAxiosError;
  if (!axiosError.isAxiosError) {
    return undefined;
  }

  const responseBody = axiosError.response?.data as ApiResponse<unknown>;
  if (!responseBody) {
    return undefined;
  }

  return responseBody.error?.message;
};
